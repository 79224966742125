import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1eab5d9c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "popup-main"
}
const _hoisted_2 = { class: "popup-container" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "body" }
const _hoisted_5 = { class: "buttons" }
const _hoisted_6 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, { name: "popup" }, {
    default: _withCtx(() => [
      (_ctx.props.showPopup)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.props.title || 'Title'), 1),
              _createElementVNode("div", _hoisted_4, [
                _renderSlot(_ctx.$slots, "default", {}, undefined, true)
              ]),
              _createElementVNode("div", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.props.buttons || [ { buttonText: 'OK' } ], (b, i) => {
                  return (_openBlock(), _createElementBlock("button", {
                    key: i,
                    style: _normalizeStyle(b),
                    onClick: ($event: any) => (_ctx.buttonClicked(b.buttonText))
                  }, _toDisplayString(b.buttonText), 13, _hoisted_6))
                }), 128))
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}