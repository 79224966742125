
/* eslint-disable */
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
  },
  props: {
    title: String,
    showPopup: Boolean,
    buttons: Array,
  },
  setup(props, { emit }) {
    //#region Data
    
    //#endregion
    
    //#region Methods
    const buttonClicked = (text: string) => {
      emit('buttonClicked', text);
    }
    //#endregion
    
    return {
      props,

      //#region Data
      
      //#endregion
      
      //#region Methods
      buttonClicked,
      //#endregion
    }
  },
})
